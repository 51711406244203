<template>
<v-card class="my-4">
  <v-card-text class="py-0">
  <v-row>
    <v-col cols="9">
    {{today}} {{currentFinancialYear}}
    </v-col>
  </v-row>
  </v-card-text>
</v-card>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      today: moment("2022 12 1", "YYYY MM DD"),
      currentMonth: [],
      previousMonth: [],
      previousQuarter: [],
      thisQuarter: [],
    }
  },
  computed: {
  },
  methods: {
    setDate () {
      switch (this.dateRange) {
        case 'currentMonth':
        case 'previousMonth':
        case 'previousQuarter':
        case 'thisQuarter':
          this.$emit('getDates', this[this.dateRange])
          this.$store.dispatch('reports/setDateRange', this.dateRange)
          break
        default:
          this.$store.dispatch('reports/setDateRange', this.dateRange)
          break
      }
    },
    setDateRange () {
      this.$emit('getDates', this.customRange)
      this.$store.dispatch('reports/setDateRange', this.dateRange)
      this.$store.dispatch('reports/setCustomRange', this.customRange)
      this.modal = false
    }
  },
  computed: {
    currentFinancialYear () {
      switch(moment().month()) {
        case 11:
          const thisYear = `${moment().year()} 12 1`
          return moment(thisYear, "YYYY MM DD")
        default:
          const lastYear = `${moment().subtract(1, 'years').year()} 12 1`
          return moment(lastYear, "YYYY MM DD")
      }
    }
  },
  async created () {
    const quarterOffset = {
      0: 1,
      1: 2,
      2: 0,
      3: 1,
      4: 2,
      5: 0,
      6: 1,
      7: 2,
      8: 0,
      9: 1,
      10: 2,
      11: 0
    }
    const lastMonthEnd = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    const lastMonthStart = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
    const thisQuarterStart = moment().subtract(quarterOffset[moment().month()], 'months').startOf('month').format('YYYY-MM-DD')
    const lastQuarterStart = moment(thisQuarterStart).subtract(3, 'months').startOf('month').format('YYYY-MM-DD')
    const lastQuarterEnd = moment(thisQuarterStart).subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    const thisMonthEnd = moment().endOf('month').format('YYYY-MM-DD')
    const thisMonthStart = moment().startOf('month').format('YYYY-MM-DD')
    this.currentMonth = [thisMonthStart, thisMonthEnd]
    this.previousMonth = [lastMonthStart, lastMonthEnd]
    this.previousQuarter = [lastQuarterStart, lastQuarterEnd]
    this.thisQuarter = [thisQuarterStart, thisMonthEnd]
    this.$emit('getDates', this.dateRange === 'custom' ? this.customRange : this[this.dateRange])
  }
}
</script>
