<template>
<v-container fluid>
    <!-- <select-financial-year></select-financial-year> -->
    <date-range @getDates="getSales"></date-range>
      <select-artist class="py-2"></select-artist>
  <h3 class="d-flex justify-space-between"><span>Total turnover: £{{totalRevenue.toLocaleString('en-GB')}}</span> <span v-if="activeArtist">Artist turnover: £{{revenue.toLocaleString('en-GB')}}</span></h3>
  <v-data-table
    :headers="headers"
    :items="filteredArtworks"
    class="elevation-1"
    :loading="loading"
  >
    <template v-slot:[`item.image`]="{ item }">
      <v-img class="my-2" max-height="50px" max-width="50px"
          :src="`${imageUrl}/100px/${getImageToDisplay(item.image)}`"
        ></v-img>
    </template>
    <template v-slot:[`item.totalSales`]="{ item }">
      £{{item.totalSales.toFixed(0)}}
    </template>
    <template v-slot:[`item.products`]="{ item }">
      <div class="text-right" v-for="product of item.products">
      {{product.product}} - £{{product.total.toFixed()}}
      </div>
    </template>
  </v-data-table>
</v-container>
</template>

<script>
// import PlanetChart from './components/PlanetChart.vue'
import { mapGetters } from 'vuex'
import ReportApi from '@/api/admin/report'
import DateRange from '@/components/DateRange'
import _ from 'lodash'
import xlsx from 'xlsx'
import SelectFinancialYear from '@/components/SelectFinancialYear'
import SelectArtist from '@/components/SelectArtist'

export default {
  components: {
    SelectFinancialYear,
    SelectArtist,
    DateRange
  },
  data () {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      artworkSales: {data: [] },
      itemCodes: null,
      totalRevenue: 0,
      loading: true,
      headers: [
        {
          text: 'Image',
          sortable: false,
          value: 'image'
        },
        {
          text: 'Code',
          value: 'artworkCode',
          width: '10%',
        },
        {
          text: 'Artist',
          value: 'artist'
        },
        {
          text: 'Title',
          width: '50%',
          value: 'title'
        },
        {
          text: 'Product',
          width: '25%',
          value: 'products'
        },
        {
          text: 'Total Sales',
          align: 'end',
          value: 'totalSales'
        },
      ]
    }
  },
  methods: {
    async getSales (dates) {
      this.artworkSales = await ReportApi.loadProductSalesByArtwork({dateRange: dates})
      this.loading = false
      for ( const item of this.artworkSales.data) {
        this.totalRevenue += item.totalSales
      }
    },
  },
  computed: {
    ...mapGetters('reports', ['activeArtist']),
    filteredArtworks () {
      return this.artworkSales.data.filter( item => {
        return this.activeArtist ? item.artistCode === this.activeArtist : true
      })
    },
    revenue () {
      let revenue = 0
      for ( const item of this.filteredArtworks) {
        revenue += item.totalSales
      }
      console.log(revenue)
      return revenue
    },
  },
  async created () {
      this.itemCodes = await ReportApi.getXeroItemCodes()
      this.loading = false
      for ( const item of this.artworkSales.data) {
        this.totalRevenue += item.totalSales
      }

  }
}
</script>
